

const transitionEndEvent = (() => {
	let t;
	const el = document.createElement('div');
	const transitions = {
	  'transition' : 'transitionend',
	  'OTransition' : 'oTransitionEnd',
	  'MozTransition' : 'transitionend',
	  'WebkitTransition' : 'webkitTransitionEnd'
	}
	for (t in transitions) {
	    if (el.style[t] !== undefined) {
	        return transitions[t];
	    }
	}
})();


function transitionDuration(el) {
	const style = getComputedStyle(el);
	if (
		!style ||
		!style.transitionDuration ||
		!parseFloat(style.transitionDuration)
	) return false;
	return style.transitionDuration;
}


function getMaxTransitionDuration(el) {

	function extract(str) {
	    return str
	    	.replace(/[A-Z]/gi, "")
	    	.split(", ")
	    	.map(parseFloat);
	};

	const style = getComputedStyle(el);

	if (
		!style ||
		!style.transitionProperty
	) return false;

	const props = style.transitionProperty.split(", ");
	const delays = extract(style.transitionDelay);
	const durations = extract(style.transitionDuration);
	const totals = durations.map(function(v, i) {
		return v + delays[i];
	});

	let max = totals[0], maxIndex = 0;

	for (var i = 1; i < totals.length; i++) {
		if (totals[i] > max) {
			maxIndex = i;
			max = totals[i];
		}
	}
	return {
		prop: props[maxIndex],
		duration: durations[maxIndex]
	}
}


function classChange(el, addClass, removeClass, callback = function(){}) {

	if (el instanceof jQuery) el = el.get(0);
	if (!el) return false;

	if (!addClass && !removeClass) {	
		callback && callback(el);
		return el;
	}

	// display the element to start the animation, and trigger reflow
	// (whether hiding or showing, the element should be visible in both cases)
	el.style.display = 'block';
	el.getBoundingClientRect();

	// after first transition
	const transitionEndHandler = (event) => {

		// stop events bubbling up to other elements
		event && event.stopPropagation(); 

		// make sure we're only firing on the current elem's event and not its children
		if (event && el !== event.target) return false;

		// only fire this once
		event && el.removeEventListener(event.type, transitionEndHandler);

		window.requestAnimationFrame(function() {
			// remove display state after animation
			el.style.display = '';
			callback(el);
		});
	};

	// setup event handler for transitionend
	transitionEndEvent && el.addEventListener(transitionEndEvent, transitionEndHandler, false);

	// add/remove class and start animation
	(addClass) && el.classList.add(addClass);
	(removeClass) && el.classList.remove(removeClass);

	// if no transition property is set, show/hide immediately
	const duration = transitionDuration(el);
	if (!duration || !transitionEndEvent) transitionEndHandler();

	return el;
}


export function addClass(el, className, callback = function(){}) {
	return classChange(el, className, null, callback);
}


export function removeClass(el, className, callback = function(){}) {
	return classChange(el, null, className, callback);
}


export function showHide(el, showClass, show, callback = function(){}) {	
	if (show) {
		return classChange(el, showClass, null, callback);
	} else {
		return classChange(el, null, showClass, callback);
	}
}


export function show(el, showClass, callback = function(){}) {
	return showHide(el, showClass, true, callback);
}


export function hide(el, showClass, callback = function(){}) {
	return showHide(el, showClass, false, callback);
}


export function toggle(el, showClass, callback = function() {}) {
	const isOpen = el.classList.contains(showClass);
	return (isOpen) ? hide(el, showClass, callback) : show(el, showClass, callback);
}


export function slideDown(el, showClass, callback = function(){}) {

	const clientHeight = el.clientHeight;
	const naturalHeight = (function() {
		el.style.height = 'auto';
		el.style.display = 'block';
		let naturalHeight = el.clientHeight;
		el.style.display = '';
		el.style.height = '';
		return naturalHeight;
	})();

	// set initial height
	if (naturalHeight > clientHeight) {
		// current height is smaller than natural height, start here
		el.style.height = clientHeight + 'px';
	} else {
		// start height at 0
		el.style.height = 0;
	}
	
	// show it
	show(el, showClass, function() {
		// remove height attr
		el.style.height = '';
		callback(el);
	});

	// set natural
	el.style.height = naturalHeight + 'px';

	return el;
}


export function slideUp(el, showClass, callback = function(){}) {

	// set height to current height
	el.style.height = el.clientHeight + 'px';
	// reflow
	el.getBoundingClientRect();
	// set to 0 height
	el.style.height = 0;

	// hide it
	hide(el, showClass, function() {
		// remove once it's done
		el.style.height = '';
		callback(el);
	});

	return el;
}


export function slideToggle(el, showClass, callback = function(){}) {
	const isOpen = el.classList.contains(showClass);
	return (isOpen) ? slideUp(el, showClass, callback) : slideDown(el, showClass, callback);
}


export function reflow(el) {
	// cause synchronous DOM read by reading box metrics
	if (el instanceof jQuery) el = el.get(0);
	el.getBoundingClientRect();
}


export function onTransitionEnd(el, callback = function(){}) {

	if (el instanceof jQuery) el = el.get(0);

	// do callback if supports transition events
	transitionEndEvent && elem.addEventListener(transitionEndEvent, function cb(event) {
		callback(event);
		// only run once
		event.currentTarget.removeEventListener(event.type, cb);
	});

	// do callback straight away if it doesn't
	!transitionEndEvent && callback(false);

}


export class Animate {
	constructor(el) {
		this.el = el;
	}
	show(howClass, callback = function(){}) {
		return showHide(this.el, showClass, true, callback);
	}
	hide(showClass, callback = function(){}) {
		return showHide(this.el, showClass, false, callback);
	}
	toggle(showClass, callback = function() {}) {
		const isOpen = this.el.classList.contains(showClass);
		function cb(type) {
			return function() { return callback(type); }
		}
		return (isOpen) ? hide(this.el, showClass, cb('hide')) : show(this.el, showClass, cb('show'));
	}
	slideDown(showClass, callback = function() {}) {
		return slideDown(this.el, showClass, callback);
	}
	slideUp(showClass, callback = function() {}) {
		return slideUp(this.el, showClass, callback);
	}
	slideToggle(showClass, callback = function() {}) {
		return slideToggle(this.el, showClass, callback);
	}
}


export default {
	addClass, removeClass,
	show, hide, toggle, 
	slideDown, slideUp, slideToggle, 
	reflow, 
	onTransitionEnd, 
	transitionEndEvent, transitionDuration, getMaxTransitionDuration,
	Animate
}


